import React from "react"

import Label from "components/_ui/Label/Label"
import Layout from "components/Layout/Layout"
import MaxWidth from "components/_ui/MaxWidth/MaxWidth"
import Link from "components/_ui/Link/Link"
import Spinner from "components/_ui/Spinner/Spinner"
import "./404.scss"

const NotFoundPage = () => (
  <Layout showNavigation showSocialCol showFooter>
    <MaxWidth className="NotFound">
      <Spinner className="NotFound__spinner__container" pet="oscar" size="xl" />
      <h1 className="NotFound__header">Page not found</h1>
      <div className="NotFound__recent">
        <Link
          doOpenInNewTab
          isButton
          className="NotFound__button"
          buttonProps={{ size: "xl", color: "white-blue-stroke" }}
          to={"/"}
        >
          Back to homepage
        </Link>
      </div>
    </MaxWidth>
  </Layout>
)

export default NotFoundPage
